import React, { useEffect, useState } from "react";
import { graphql } from 'gatsby';

import {cloneDeep, flatten, filter} from 'lodash';

import Layout from "../components/layout";
import SEO from "../components/seo";

const NotFoundPage = props => {

    const language_id = 1;
    const impressum = filter(props.data.allImpressum.nodes,function(impressum){ return impressum.language_id == language_id})[0];


    return (
        <Layout pathContext={props.pathContext}>
            <SEO title="Imprint" />

                {impressum && (
                    <section className="impressum_section">
                        <div className="impressum_wrapper main_content_wrapper">
                            <h1>{impressum.title}</h1>

                            <div
                                className="impressum_text_wrapper"
                                dangerouslySetInnerHTML={{
                                    __html: impressum.description
                                }}
                            ></div>

                            {/* <p><strong>Fördergeber</strong></p> */}
                            
                            <div className="impressum_logos_section">
                                <img className="impressum_logo big_logo" src="/images/leaderlogo.svg" alt="Leaderlogo" />
                                <img className="impressum_logo" src="/images/noe_logo_1.0.png" alt="NOE Logo" />
                                <img className="impressum_logo" src="/images/noe_logo_2.0.png" alt="NOE Logo" />
                            </div>

                        </div>
                    </section>

                )}

        </Layout>
    );
};

export default NotFoundPage


export const query = graphql`
    query ImpressumEn {
        allImpressum {
            nodes {
                title
                language_id
                description
            }
        }
    }
`
  